<template>
    <div class="staff">
        <div class="left-top">
            <span style="font-size: 18px; font-weight: 400; color: #333"
            >帮助文档管理</span>
           <el-divider></el-divider>
           <el-form
            :model="searchForm"
            :inline="true"
            ref="searchForm"
            class="searchForm"
           >
            <el-form-item label="文章标题:" prop="Title">
                <el-input
                    style="width: 200px"
                    v-model="searchForm.articleName"
                    placeholder="输入文章标题搜索"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="keysSearch(1)">
                    <i class="el-icon-search" style="margin-right: 5px"></i>
                    搜索
                </el-button>
        </el-form-item>
           </el-form>
        </div>
        <el-table 
        :data="PageConfigure"
        row-key="Id"
        style="width:100%"
        >
            <el-table-column
            fixed
            label="序号"
            width="80"
            align="center"
            type="index"
            sortable
            >
            </el-table-column>
            <el-table-column  label="文章标题" width="700" align="left">
                <template slot-scope="scope">
                    <a style="color:blue;text-decoration:underline;" :href="`https://www.1renshi.com/News/${scope.row.Id}.html`" target="_blank"><span>{{scope.row.Title}}</span></a>
                </template>
            </el-table-column>
            <el-table-column  label="文章类型" width="150" prop="CategoryName" align="center"> 
            </el-table-column>
            <el-table-column  label="排序" width="100" prop="SortNumber" align="center"> 
            </el-table-column>
            <el-table-column  fixed="right"  label="操作" align="center"> 
                <!-- 定义一个编辑子组件 -->
                <template slot-scope="scope">
                    <span
                        class="backSpan"
                        @click = "editSortNumber(scope.row)"
                        style="color: #409eff"
                    >
                         编辑
                    </span>
                </template>
            </el-table-column>      
        </el-table>
        <!-- 翻页区域,在数据不为空时显示 -->
        <div class="task-list-page" v-if="PageConfigure != ''">
            <!-- 分页组件，定义页码变化、显示条目两个事件 -->
            <el-pagination
            @current-change="OnCurrentPageChanged"
            @size-change="handleSizeChange" 
            :current-page="Params.PageIndex"
            :page-sizes="[10, 20, 30, 40, 50, 60]"
            layout="prev, pager, next,sizes,jumper,total"
            :page-size="Params.PageSize"
            background
            :total="Params.TotalCount"  
            >
            </el-pagination>
        </div>
        <!-- 编辑模态框 visible是否显示-->
        <div class="formdia">
            <el-dialog
            :visible.sync="dialogFormVisible"
            custom-class="edit-form"
            :title="dialongtitle"
            :modal-append-to-body="false">
            <!-- 通过form表单提交 -->
                <el-form :model="Rowcontent" :rules="rules" ref="editForm">
                    <el-form-item
                    label="文章标题："
                    type="number"
                    :label-width="formLabelWidth"
                    >
                        <span >{{Rowcontent.Title}}</span>
                    </el-form-item>
                    
                    <el-form-item
                    label="文章排序:"
                    type="number"
                    :label-width="formLabelWidth"
                    prop="SortNumber"
                    >
                         <el-input
                            style="width:340px"
                            v-model="Rowcontent.SortNumber"
                            placeholder="请输入文章序号"
                            maxlength="40"
                        ></el-input>
                    </el-form-item>
                    <span style="color:red;padding-left:100px" >*排序为0，则不会在新手学堂展示</span>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">返回</el-button>
                    <el-button 
                    :disabled="isDisabled"
                    @click="submitForm('editForm')"
                    > 确定 </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>  
<script>
//引入数字校验规则
import Sortable from "sortablejs";

export default ({
    data(){
        var numberRules = (rule, value, callback) => {
            if (/^[0-9]\d*$/.test(value)) {
                callback();
            } else {
                callback(new Error("请输入整数"));
            }
         };
        return {
        dialongtitle:"",//模态框名称
        dialogFormVisible: false, //模态框是否显示
        dialongtitle:"",//模态框标题
        formLabelWidth: "100px",
        Rowcontent: {//对应列属性
            Id:"0",
            Title:"",
            CategoryGuid:"",
            CategoryName:"",
            SortNumber:"0"
        },
        //模态框填写规则提示
        rules: {
            SortNumber: [
            { required: true, message: "请完善信息", trigger: "blur" },
            { validator: numberRules, trigger: "blur" },
            ],
        },

        PageConfigure: [], //列表分页数据
        Params: {
            pageIndex: 1,
            pageSize: 10,
            TotalCount: 0,
            TotalPage: 0,
            KeyWords:""
        },
        //查询数据的变量
        searchForm: {
            articleName: "",
        },
        IsApp: false,
        IsEdit: false,
        pageconfigID: "",
        pageName: "",
        deleteStatus: false,
        Alltype:[],//获取所有分类
        value: '',
        isDisabled:false
        };
            
    },
    //初始化执行的方法
    created(){
        this.searchDate();
    },
    methods:{
        searchDate(){
            var  _this = this;
            this.$get(_this.$api.GetNoviceSchoolArticlePage,_this.Params)
            .then((res)=>{
                _this.PageConfigure = res.Data;
                _this.Params.TotalCount = res.TotalNumber;
                _this.Params.TotalPage = res.TotalPage;
                console.log(this.PageConfigure);
            }).catch(err=>{console.log(err)});
        },
        //获取分页
        OnCurrentPageChanged(val) {
        this.Params.pageIndex = val;
        console.log(this.Params.PageIndex);
        this.searchDate();
        },
        //显示条数变更方法
        handleSizeChange(val) {
        this.Params.pageSize = val;
        console.log(val);
        this.searchDate();
        },
        //编辑方法
        editSortNumber(val) {
            console.log("aaa");
            var _this=this;
            //模态框数据配置
            this.IsEdit = true;
            this.dialongtitle="编辑";
            this.dialogFormVisible = true;
            //编辑表单的内容预填写
            this.Rowcontent.Id = val.Id;
            this.Rowcontent.Title=val.Title;
            this.Rowcontent.SortNumber = val.SortNumber;
        },
        //避免重复提交
        entime(){
            setTimeout(()=>{
                this.isDisabled=false
            },500)
        },
        //编辑的提交
        submitForm(editForm) {
            var _this = this;
            this.isDisabled=true;
            this.entime();
            if(this.Rowcontent.SortNumber==''||this.Rowcontent.SortNumber==null){
                this.$message({
                message: "请填写排序编号",
                type: "error",
                duration: "1500",
                });
                return;
            }
            //如果校验通过则提交
            this.$refs[editForm].validate((valid) => {
                if(valid){
                    if(this.IsEdit){
                        this.$post(this.$api.UpdateArticleSortNumber,null,{ArticleId:this.Rowcontent.Id,SortNumber:this.Rowcontent.SortNumber})
                        .then((res) => {
                            if (res.IsSuccess) {
                                _this.$message({
                                    message: "编辑成功",
                                    type: "success",
                                    duration: "1500",
                                });
                                _this.searchDate();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                        this.dialogFormVisible = false;
                    }
                }else {
                    this.$message({
                        message: "请完善信息",
                        type: "warning",
                        duration: "1500",
                    });
                }
            });
        },
        //查询
        keysSearch(num) {
            var _this = this;
            _this.Params.KeyWords = "";
            if (
                _this.searchForm.articleName != null &&
                _this.searchForm.articleName.trim() != ""
            ) {
                _this.Params.KeyWords = this.searchForm.articleName.trim();
            }
            this.Params.PageIndex = 1;
            this.searchDate();
        }
    }
})
</script>
<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}
/*编辑框样式*/
/deep/.edit-form {
    width: 500px;
    .el-dialog__header {
        height: 50px;
        text-align: center;
        background-color: #409eff;

        .el-dialog__title {
        line-height 0px
        font-size: 22px;
        color: #fff;
        }

        .el-dialog__headerbtn {
        display: none;
        }
    }

    .el-form-item .el-textarea__inner {
        resize: none;
    }

    .el-dialog__body {
        padding-bottom: 5px !important;
    }

    .dialog-footer {
        text-align: center;

        .el-button {
        background-color: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
        }

        .el-button:hover {
        background-color: #409eff;
        border-color: #409eff;
        color: #fff;
        }
    }
    img.upImg{
        width 40px
        height 40px
        background-size 100% 100%
        float left
    }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;
   .searchForm {
    .el-form-item {
      vertical-align: middle;
      margin-top: 10px;
    }
  }

  .backSpan {
    padding: 0px 15px;
    cursor: pointer;
  }
    .task-list-page {
    margin-top: 10px;
  }

  }
</style>